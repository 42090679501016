import request from './request'
import useClipboard from 'vue-clipboard3'

function pathJoin (...args: string[]) {
  let s = args[0]
  for (let i = 1; i < args.length; i++) {
    if (s[s.length - 1] !== '/' && args[i][0] !== '/') {
      s += '/'
    }
    s += args[i]
  }
  return s
}

function object2Json (obj: object): string {
  return JSON.stringify(obj, null, 2)
}

function formatJsonSr (s: string): string {
  if (s.length === 0) {
    return ''
  }
  return object2Json(JSON.parse(s))
}

function compressJson (s: string): string {
  if (s.length === 0) {
    return ''
  }
  return JSON.stringify(JSON.parse(s))
}

async function copyToClipboard (s: string) {
  const { toClipboard } = useClipboard()
  await toClipboard(s)
}

function isMobile (): boolean {
  return window.innerWidth <= 768
}

export default {
  http: request,
  pathJoin,
  object2Json,
  formatJsonSr,
  copyToClipboard,
  compressJson,
  isMobile
}
