
import utils from '@/utils'
import { defineComponent, ref } from 'vue'

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  direction: {
    type: String,
    // rtl, ltr, ttb, btt
    default: 'rtl'
  }
}

export default defineComponent({
  name: 'Drawer',
  model: {
    prop: 'visible',
    event: 'input'
  },
  props: props,

  setup (props) {
    const width = ref('30%')
    if (utils.isMobile()) {
      width.value = '90%'
    }
    return { props, width }
  }
})
