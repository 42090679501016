import { DApp, Api, Network } from '@/services/dapps'
import { defineStore } from 'pinia'
import services from '@/services'
import utils from '@/utils'

const exampleDApp: DApp = {
  name: 'Example DApp',
  description: 'This is an example DApp',
  tags: ['example'],
  network: {
    name: 'Mainnet',
    endpoint: 'https://civilian.cpchain.io',
    chainId: 337
  },
  address: 'notexisted',
  abi: '',
  apis: [{
    name: 'API 1',
    path: '/example/path',
    description: 'description',
    ordering: [],
    filters: ['filter1', 'filter2'],
    page: 1,
    limit: 10,
    results: {
      _from: 'address',
      _to: 'address',
      _value: 'uint256'
    }
  }, {
    name: 'API 2',
    path: '/example/path2',
    description: 'description',
    ordering: [],
    filters: ['filter1', 'filter2'],
    page: 1,
    limit: 10,
    results: {
      _from: 'address',
      _to: 'address',
      _value: 'uint256'
    }
  }]
}

export const useDAppsStore = defineStore('dapps', {
  state: () => ({
    showExample: false,
    dapps: [] as DApp[],
    networks: [] as Network[],
    currentIndex: -1,
    currentApiIndex: -1,
    currentNetworkIndex: 'All',
    // pagination
    page: 1,
    limit: 10,
    // filters
    filters: new Map<string, string>(),
    filtersOp: 'and',
    // ordering
    orderBy: '',
    orderDirection: 'asc'
  }),
  getters: {
    currentDapp (): DApp | null {
      if (this.currentIndex === -1) {
        return null
      }
      return this.dapps[this.currentIndex]
    },
    currentApi (): Api | null {
      if (this.currentIndex === -1 || this.currentApiIndex === -1) {
        return null
      }
      return this.dapps[this.currentIndex].apis[this.currentApiIndex]
    },
    networkSelectors (): string[] {
      return ['All'].concat(this.networks.map(network => network.name))
    },
    shownDapps (): DApp[] {
      const dapps = this.dapps
      const network = this.currentNetworkIndex
      if (network === 'All') {
        return dapps
      }
      return dapps.filter(dapp => dapp.network?.name === network)
    },
    currentUrl (): string {
      const fullPath = utils.pathJoin(process.env.VUE_APP_SERVER || '',
        this.currentApi?.path || '')
      const pagination = `page=${this.page}&limit=${this.limit}`
      let filters = ''
      this.filters.forEach((value, key) => {
        filters += `&${key}=${value}`
      })
      const filtersOp = `&_filters_op=${this.filtersOp}`
      const ordering = `&ordering=${this.orderDirection === 'asc' ? '' : '-'}${this.orderBy}`
      return fullPath + '?' + pagination + filters + ordering + filtersOp
    }
  },
  actions: {
    async fetchDApps () {
      const dapps = await services.dappsManager.getDApps()
      if (this.showExample) {
        this.dapps = [exampleDApp, ...dapps]
      } else {
        this.dapps = dapps
      }
      this.setCurrentDapp(0)
    },
    async fetchNetworks () {
      const networks = await services.dappsManager.getNetworks()
      this.networks = networks
      this.currentNetworkIndex = 'All' // means all
    },
    async setCurrentDapp (id: number) {
      this.currentIndex = id
      this.setCurrentApi(0)
    },
    async setCurrentApi (id: number) {
      this.currentApiIndex = id
    },
    async executeApi (api: Api | undefined) {
      if (!api) {
        return
      }
      const results = await services.dappsManager.executeApi(api, this.filters)
      return results
    },
    async setCurrentNetwork (name: string) {
      this.currentNetworkIndex = name
    },
    getAddressUrl (networkName: string | undefined, address: string | undefined) {
      if (!networkName || !address) {
        return ''
      }
      // filter network
      const networks = this.networks.filter(network => network.name === networkName)
      if (networks.length === 0) {
        return ''
      }
      const network = networks[0]
      const addressUrlFmt = network.addressUrl
      return addressUrlFmt?.replace('[[address]]', address)
    }
  }
})
