import axios from 'axios'

function createInstance () {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_SERVER,
    timeout: 3000
  })
  return instance
}

const $http = createInstance()

export default $http
