
// initial theme
const storedTheme = localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

if (storedTheme) {
  document.documentElement.setAttribute('data-theme', storedTheme)
}

export function toggleTheme () {
  const theme = document.documentElement.getAttribute('data-theme')
  const newTheme = theme === 'light' ? 'dark' : 'light'
  document.documentElement.setAttribute('data-theme', newTheme)
  localStorage.setItem('theme', newTheme)
}
