
import utils from '@/utils'
import { defineComponent, ref } from 'vue'

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  }
}

export default defineComponent({
  name: 'Dialog',
  model: {
    prop: 'visible',
    event: 'input'
  },
  props: props,

  setup (props) {
    const width = ref(30)
    if (utils.isMobile()) {
      width.value = 80
    }
    const gap = 10
    function increaseWidth () {
      if (width.value < 100) {
        width.value += gap
      }
    }
    function decreaseWidth () {
      if (width.value >= 35) {
        width.value -= gap
      }
    }
    return { props, width, increaseWidth, decreaseWidth }
  }
})
