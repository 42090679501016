import utils from '@/utils'

export type SystemInfo = {
  version: string
}

export interface SystemService {
  getSystemInfo(): Promise<SystemInfo>
}

export class SystemServiceImpl {
  async getSystemInfo (): Promise<SystemInfo> {
    const r = await utils.http.get('/version')
    return r.data
  }
}
