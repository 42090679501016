import { DAppsManager, DAppsManagerImpl } from './dapps'
import { SystemService, SystemServiceImpl } from './system'

const dappsManager: DAppsManager = new DAppsManagerImpl()
const systemService: SystemService = new SystemServiceImpl()

export default {
  dappsManager,
  systemService
}
