import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "ops" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Minus = _resolveComponent("Minus")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.props.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.visible) = $event)),
    title: _ctx.props.title,
    width: '' + _ctx.width + '%'
  }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.props.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_icon, {
            size: 16,
            onClick: _ctx.increaseWidth
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_icon, {
            size: 16,
            onClick: _ctx.decreaseWidth
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Minus)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["modelValue", "title", "width"]))
}