import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: _ctx.props.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.visible) = $event)),
    title: _ctx.props.title,
    size: _ctx.width,
    "with-header": false,
    direction: _ctx.props.direction
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["modelValue", "title", "size", "direction"]))
}