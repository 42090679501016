import Shepherd from 'shepherd.js'
import classes from './classes'

type Placement = Shepherd.Step.PopperPlacement
const placements = {
  right: <Placement>'right',
  left: <Placement>'left',
  bottom: <Placement>'bottom'
}

const classSelector = (className: string) => `.${className}`

const steps = [
  {
    element: classSelector(classes.sidebar),
    content: 'This is the sidebar. You can use it to navigate to different dapps.',
    placement: placements.right
  }, {
    content: 'This is a dapp. Click on it to see its APIs.',
    element: classSelector(classes.firstDapp),
    placement: placements.right
  }, {
    content: 'This is API, click it to see its details.',
    element: classSelector(classes.firstApi),
    placement: placements.bottom
  }
]

export default steps
