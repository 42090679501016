import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'

export type Step = {
  id?: string
  content: string
  element: string
  placement?: Shepherd.Step.PopperPlacement
}

const tour = new Shepherd.Tour({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: 'shadow-md bg-purple-dark',
    // 显示关闭按钮
    cancelIcon: {
      enabled: true
    },
    scrollTo: { behavior: 'smooth', block: 'center' },
    // 高亮元素四周要填充的空白像素
    modalOverlayOpeningPadding: 8,
    // 空白像素的圆角
    modalOverlayOpeningRadius: 4,
    buttons: [{
      action () {
        return this.back()
      },
      text: 'Prev'
    }, {
      action () {
        return this.next()
      },
      text: 'Next'
    }]
  }
})

let idSeq = 0

export function addStep (step: Step) {
  let id = step.id
  if (!id) {
    id = `id-${idSeq++}`
  }
  tour.addStep({
    id: id,
    text: step.content,
    attachTo: {
      element: step.element,
      on: step.placement || 'bottom'
    },
    classes: 'example-step-extra-class',
    buttons: [
      {
        text: 'Next',
        action: tour.next
      }
    ]
  })
}

export function startTour () {
  // 是否已引导过
  const hasTour = localStorage.getItem('hasTour')
  if (hasTour) {
    return
  }
  setTimeout(() => {
    tour.start()
    // 引导过后，记录引导过
    localStorage.setItem('hasTour', '1')
  })
}

export function clearHasTour () {
  localStorage.removeItem('hasTour')
}
