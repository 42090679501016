import { defineStore } from 'pinia'
import services from '@/services'
import { ElLoading } from 'element-plus'

interface Loading {
  close(): void
}

export const useSystemStore = defineStore('system', {
  state: () => ({
    version: '',
    loading: null as Loading | null
  }),
  actions: {
    async fetchInfo () {
      const systemInfo = await services.systemService.getSystemInfo()
      this.version = systemInfo.version
    },
    showLoading (text = 'Loading Dapps...') {
      this.loading = ElLoading.service({
        fullscreen: true,
        text: text
      })
    },
    hideLoading () {
      this.loading?.close()
      this.loading = null
    }
  }
})
