import utils from '@/utils'

export type Api = {
  name: string;
  description: string;
  path: string
  ordering: string[]
  filters: string[]
  filtersOp?: string,
  results: object
  // pagination
  page: number
  limit: number
}

export type ApiResults = {
  count: number
  results: object[]
}

export type Network = {
  name: string
  endpoint?: string
  chainId?: number
  homepage?: string
  addressUrl?: string
  txUrl?: string
}

export type DApp = {
  name: string;
  description?: string,
  tags?: string[],
  network?: Network
  address?: string
  apis: Api[],
  abi?: string
}

export interface DAppsManager {
  getDApps(): Promise<DApp[]>
  getNetworks(): Promise<Network[]>
  executeApi(api: Api, filters: object|undefined): Promise<ApiResults>
}

export class DAppsManagerImpl implements DAppsManager {
  async getDApps (): Promise<DApp[]> {
    const r = await utils.http.get('/apis')
    const data: DApp[] = []
    for (const dappName in r.data) {
      const dapp = r.data[dappName]
      const apis: Api[] = []
      for (const api of dapp.apis) {
        apis.push({
          name: api.model_name,
          description: api.description,
          path: api.path,
          ordering: api.ordering,
          filters: api.filters,
          results: api.results,
          page: 1,
          limit: 10
        })
      }
      data.push({
        name: dapp.name,
        tags: dapp.tags,
        description: dapp.description,
        network: dapp.network,
        address: dapp.address,
        abi: dapp.abi,
        apis
      })
    }
    return data
  }

  async executeApi (api: Api, filters: Map<string, string>): Promise<ApiResults> {
    // if example dapp, mock results
    if (api.path.startsWith('/example')) {
      const fakeResults = []
      for (let i = 0; i < 10; i++) {
        fakeResults.push({
          _from: '0x' + Math.floor(Math.random() * 100000).toString(16),
          _to: '0x' + Math.floor(Math.random() * 100000).toString(16),
          _value: Math.floor(Math.random() * 100000)
        })
      }
      return {
        count: 10,
        results: fakeResults
      }
    }
    // pagination
    const pagination = '?page=' + api.page + '&limit=' + api.limit
    // filters
    let otherQuery = ''
    filters.forEach((value, key) => {
      otherQuery += '&' + key + '=' + value
    })
    // filtersOp
    if (api.filtersOp) {
      otherQuery += '&_filters_op=' + api.filtersOp
    }
    // ordering
    let ordering = ''
    if (api.ordering.length > 0) {
      // TODO 支持多条件排序
      ordering = '&ordering=' + api.ordering[0]
    }
    const r = await utils.http.get(api.path + pagination + otherQuery + ordering)
    return {
      count: r.data.count,
      results: r.data.results
    }
  }

  async getNetworks (): Promise<Network[]> {
    const r = await utils.http.get('/networks')
    return r.data
  }
}
